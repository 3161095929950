export const FETCH_LISTINGS_REQUEST = 'app/AllListingsPage/FETCH_LISTINGS_REQUEST';
export const FETCH_LISTINGS_SUCCESS = 'app/AllListingsPage/FETCH_LISTINGS_SUCCESS';
export const FETCH_LISTINGS_ERROR = 'app/AllListingsPage/FETCH_LISTINGS_ERROR';
export const fetchListings = () => async (dispatch, getState, sdk) => {
  dispatch({ type: FETCH_LISTINGS_REQUEST });

  try {
    // Fetch all listings (without images)
    const response = await sdk.listings.query({
      per_page: 12,
    });
    let listings = response.data.data;

    // Fetch each listing individually to get images and reviews
    const listingsWithImages = await Promise.all(
      listings.map(async (listing) => {
        try {
          const listingResponse = await sdk.listings.show({
            id: listing.id.uuid,
            include: ['images'],
          });

          const reviewsResponse = await sdk.reviews.query({
            listing_id: listing.id.uuid,
            per_page: 20,
            'fields.review': ['rating', 'comment', 'createdAt'],
            sort: '-created_at',
          });

          const reviews = reviewsResponse.data.data.map((review) => ({
            id: review.id.uuid,
            rating: review.attributes.rating,
          }));

          const includedImages = listingResponse.data.included || [];
          const imageUrls = includedImages
            .filter((img) => img.type === 'image')
            .map((img) => img.attributes?.variants?.default?.url)
            .filter(Boolean);

          return {
            ...listing,
            imageUrls, // Add image URLs to listing
            reviews,
            averageRating:
              reviews.length > 0
                ? reviews.reduce((sum, r) => sum + r.rating, 0) / reviews.length
                : 0,
            price: listing.attributes.price?.amount || 0, // Get price amount
          };
        } catch (error) {
          console.error(`Error fetching images for listing ${listing.id.uuid}:`, error);
          return { ...listing, price: listing.attributes.price?.amount || 0 }; // Return listing with price
        }
      })
    );

    // Sort listings by price in descending order
    listingsWithImages.sort((a, b) => b.price - a.price);

    dispatch({
      type: FETCH_LISTINGS_SUCCESS,
      payload: { data: listingsWithImages },
    });
  } catch (error) {
    dispatch({ type: FETCH_LISTINGS_ERROR, payload: error });
  }
};
