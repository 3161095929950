import styled from 'styled-components';

// Reuse existing container components from previous implementation
const PaymentFooterContainer = styled.div`
  border-top: 1px solid #d1d5db;
  background-color: #f0fdfa;
`;

const MaxWidthContainer = styled.div`
  margin-left: auto;
  margin-right: auto;
  max-width: 80rem;
  padding-left: 1rem;
  padding-right: 1rem;

  @media (min-width: 640px) {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }

  @media (min-width: 1024px) {
    padding-left: 2rem;
    padding-right: 2rem;
  }
`;

const GridContainer = styled.div`
  display: grid;
  align-items: center;
  gap: 1.25rem;
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;

  @media (min-width: 1024px) {
    grid-template-columns: repeat(3, 1fr);
  }
`;

// New styled components for the added elements
const CopyrightText = styled.div`
  text-align: center;
  font-size: 0.875rem;
  line-height: 1.25rem;
  font-family: /* Add your actual font family here */;
`;

const LinksContainer = styled.div`
  display: flex;
  justify-content: center;
  gap: 1.25rem;
  font-size: 0.875rem;
  margin-right: 0.5rem;

  @media (min-width: 1024px) {
    justify-content: flex-end;
  }

  a {
    color: inherit;
    text-decoration: none;
    &:hover {
      text-decoration: underline;
    }
  }
`;

// Reuse existing payment components
const PaymentMethodsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
  justify-content: center;
`;

const PaymentImage = styled.img`
  height: 0.8rem;
`;

const PaymentFooter = () => (
  <PaymentFooterContainer>
    <MaxWidthContainer>
      <GridContainer>
        <div>
          <CopyrightText>© 2024 Hour&More. جميع الحقوق محفوظة. </CopyrightText>
        </div>

        <PaymentMethodsContainer>
          <PaymentImage src={process.env.PUBLIC_URL + '/static/img/mada.svg'} alt="Mada" />
          <PaymentImage src={process.env.PUBLIC_URL + '/static/img/apple-pay.svg'} alt="Apple Pay" />
          <PaymentImage src={process.env.PUBLIC_URL + '/static/img/mastercard-logo.svg'} alt="Mastercard" />
          <PaymentImage src={process.env.PUBLIC_URL + '/static/img/stc-pay.svg'} alt="STC Pay" />
          <PaymentImage src={process.env.PUBLIC_URL + '/static/img/visa-logo.svg'} alt="Visa" />
        </PaymentMethodsContainer>

        <div>
          <LinksContainer>
            <a href="/terms-of-service">شروط الاستخدام </a>
            <a href="/privacy-policy">سياسة الخصوصية</a>
          </LinksContainer>
        </div>
      </GridContainer>
    </MaxWidthContainer>
  </PaymentFooterContainer>
);

export default PaymentFooter;
